import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const mailChimpTemplateReady = () => (

    <table style={{ margin: "0px", fontFamily: "Roboto" }}>
        <tr>
            <td style={{
                border: "none", borderRight: "1px solid rgba(0, 0, 0, 0.125)", width: "80%"
            }}>
                <p style={{ marginBottom: "0px", fontWeight: "bold" }}>MailChimp Template Ready</p>
                <p style={{ marginBottom: "0px", fontWeight: "normal", fontSize: "14px" }}>Simply paste add our hand coded HTML into your Mailchimps template designer and be able to change your images and textual content on the go. <br />
                    <span style={{ color: "#39ace7", fontWeight: "bold" }}> User Freindly Interface</span>
                </p>
            </td>
            <td className="text-center" style={{ border: "none" }}>

                <FontAwesomeIcon icon={faCheck} color="#39ace7" />
            </td>
        </tr>
    </table>

)

export default mailChimpTemplateReady;
import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const directCommunication = () => (

    <table style={{ margin: "0px", fontFamily: "Roboto" }}>
        <tr>
            <td style={{
                border: "none", borderRight: "1px solid rgba(0, 0, 0, 0.125)", width: "80%"
            }}>
                <p style={{ marginBottom: "0px", fontWeight: "bold" }}>Direct Communication</p>
                <p style={{ marginBottom: "0px", fontWeight: "normal", fontSize: "14px" }}>Talk directly with the person
                building your email 24/7.
 </p>
            </td>
            <td className="text-center" style={{ border: "none" }}>

                <FontAwesomeIcon icon={faCheck} color="#ffffff" />
            </td>
        </tr>
    </table>

)

export default directCommunication;
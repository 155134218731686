import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'


const emailClientTestReport = () => (
    <table style={{ margin: "0px", fontFamily: "Roboto" }}>
        <tr>
            <td style={{
                border: "none", borderRight: "1px solid rgba(0, 0, 0, 0.125)", width: "80%"
            }}>
                <p style={{ marginBottom: "0px", fontWeight: "bold" }}>Email Client Test Report</p>
                <p style={{ marginBottom: "0px", fontWeight: "normal", fontSize: "14px" }}>The HTML markup wil be testing against
                25+ of the most popular email
                clients to enure your email
                        looks great across the board and a test report will be supplied.<br />
                    <span style={{ color: "#39ace7", fontWeight: "bold" }}> Peace of Mind </span>  </p>
            </td>
            <td className="text-center" style={{ border: "none" }}>

                <FontAwesomeIcon icon={faCheck} color="#39ace7" />
            </td>
        </tr>
    </table>

)

export default emailClientTestReport;
import React from "react"
import { Link } from "gatsby"

import Blog1 from "../images/blog1"
import Blog2 from "../images/blog2"
import Blog3 from "../images/blog3"
import Blog4 from "../images/blog4"


import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"

import blog1Img from "../../images/Mailsculpt-Image-3.jpg"
import Blog2Img from "../../images/MailSculpt-Image-2.jpg"
import Blog3Img from "../../images/MailSculpt-Blog-1.png"
import blog4Img from "../../images/Mail-Sculpt-4.jpg"


const ArchiveSection = () => (

    <div id="blogs" style={{ backgroundColor: "#ffffff", height: "100%", width: "100%", marginBottom: "80px", paddingTop: "30px" }}>
        <Container style={{ maxWidth: "900px", fontFamily: "Roboto" }} >

            <Row>
                <Col>
                    {/* <p className="h1 text-center" style={{ marginTop: "70px", fontWeight: "normal", fontFamily: "Roboto" }}>Latest Blogs</p> */}
                    <p className="h1 text-center" style={{ marginTop: "70px", fontWeight: "normal", fontFamily: "Roboto" }}>Free Resources For Email Design</p>
                </Col>

            </Row>

            <Row className="justify-content-md-center" style={{ marginTop: "80px" }}>


                <Col sm={12} md={6} style={{ marginTop: "40px" }}>
                    <Card style={{ maxWidth: "402px", marginLeft: "auto", marginRight: "auto" }}>


                        <img src={blog4Img} />
                        <Card.Body>

                            <p style={{ fontFamily: "Roboto" }}>28th August, 2020</p>

                            <Card.Title>6 Types of emails you must send your subscribers</Card.Title>
                            <Card.Text>
                                Now that you have subscribers, it’s time to make a list of emails you will send to keep them engaged .....
    </Card.Text>
                            <Link to="/6-types-of-emails-you-must-send-your-subscribers/" style={{ textDecoration: "none", color: "#ffffff" }}><Button variant="primary">Read More</Button></Link>
                        </Card.Body>
                    </Card>
                </Col>






                <Col sm={12} md={6} style={{ marginTop: "40px" }}>
                    <Card style={{ maxWidth: "402px", marginLeft: "auto", marginRight: "auto" }}>


                        <img src={Blog3Img} />
                        <Card.Body>

                            <p style={{ fontFamily: "Roboto" }}>17th August, 2020</p>

                            <Card.Title>Anatomy of a high-converting email design</Card.Title>
                            <Card.Text>
                                Email design is essential to convey the information in a clear, readable way so the subscriber can move on to take action.....
    </Card.Text>
                            <Link to="/anatomy-of-a-high-converting-email-design/" style={{ textDecoration: "none", color: "#ffffff" }}><Button variant="primary">Read More</Button></Link>
                        </Card.Body>
                    </Card>
                </Col>


            </Row>

            <Row className="justify-content-md-center" >




                <Col sm={12} md={6} style={{ marginTop: "40px" }}>
                    <Card style={{ maxWidth: "402px", marginLeft: "auto", marginRight: "auto" }}>

                        {/* <Blog1 /> */}
                        <img src={Blog2Img} />
                        <Card.Body>

                            <p style={{ fontFamily: "Roboto" }}>13th August, 2020</p>

                            <Card.Title>Email Newsletter Ideas For 2020</Card.Title>
                            <Card.Text>
                                Take a look at 5 best newsletter ideas and see what makes them stand out. Take these ideas and use them to present their content in the best possible form.....
    </Card.Text>
                            <Link to="/email-newsletter-ideas-for-2020/" style={{ textDecoration: "none", color: "#ffffff" }}><Button variant="primary">Read More</Button></Link>
                        </Card.Body>
                    </Card>
                </Col>








                {/* <Col sm={12} md={6} style={{ marginTop: "20px" }}> */}
                <Col style={{ marginTop: "40px" }}>
                    <Card style={{ maxWidth: "402px", marginLeft: "auto", marginRight: "auto" }}>

                        {/* <Blog1 /> */}
                        <img src={blog1Img} />
                        <Card.Body>

                            <p style={{ fontFamily: "Roboto" }}>27th July, 2020</p>

                            <Card.Title>4 Tips To Improve Your Email Campaign</Card.Title>
                            <Card.Text>
                                Actionable tips to get more sales, better engagement, and high ROI from your email campaigns....
    </Card.Text>
                            <Link to="/4-tips-to-improve-your-email-campaign/" style={{ textDecoration: "none", color: "#ffffff" }}><Button variant="primary">Read More</Button></Link>
                        </Card.Body>
                    </Card>
                </Col>


            </Row>

            {/* <Row className="justify-content-md-center" > */}




            {/* <Col>
                    <Card style={{ width: "402px" }}>

                        <Blog2 />
                        <Card.Body>
                            <Card.Title>Email send deliverability, is it just in the subject line??</Card.Title>
                            <Card.Text>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...
    </Card.Text>
                            <Button variant="primary">Read More</Button>
                        </Card.Body>
                    </Card>
                </Col>

            </Row>




            <Row style={{ marginTop: "40px" }}>
                <Col>
                    <Card style={{ width: "402px" }}>

                        <Blog3 />
                        <Card.Body>
                            <Card.Title>How to enusre you email acutally gets delivered!</Card.Title>
                            <Card.Text>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...
    </Card.Text>
                            <Button variant="primary">Read More</Button>
                        </Card.Body>
                    </Card>
                </Col>

                <Col>
                    <Card style={{ width: "402px" }}>

                        <Blog4 />
                        <Card.Body>
                            <Card.Title>Make you email campagne stand out from the rest!</Card.Title>
                            <Card.Text>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...
    </Card.Text>
                            <Button variant="primary">Read More</Button>
                        </Card.Body>
                    </Card>
                </Col> */}

            {/* </Row> */}



        </Container>



    </div >



)

export default ArchiveSection
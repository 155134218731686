import React from "react"

import Showcase1 from "../images/showcase1"
import Showcase2 from "../images/showcase2"
import Showcase3 from "../images/showcase3"
import Showcase4 from "../images/showcase4"
import Showcase5 from "../images/showcase5"
import Showcase6 from "../images/showcase6"
import Showcase7 from "../images/showcase7"
import Showcase8 from "../images/showcase8"


import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"


const ShowcaseSection = () => (



    <div id="showcase" style={{ backgroundColor: "#192128", height: "1320px", width: "100%", paddingtop: "20px" }}>
        <Container style={{ width: "1200px" }} >

            <Row>
                <Col><p className="h1 text-center" style={{ marginTop: "70px", fontWeight: "normal", color: "#ffffff", fontFamily: "Roboto" }}>Showcase</p></Col>
            </Row>

            <Row style={{ marginTop: "50px" }}>
                <Col className="text-center" >
                    <Showcase1 />

                </Col>
                <Col className="text-center" >
                    <Showcase2 />

                </Col>

                <Col className="text-center" >
                    <Showcase3 />

                </Col>

                <Col className="text-center" >
                    <Showcase4 />

                </Col>
            </Row>

            <Row style={{ marginTop: "50px" }}>
                <Col className="text-center" >
                    <Showcase5 />

                </Col>
                <Col className="text-center" >
                    <Showcase6 />

                </Col>

                <Col className="text-center" >
                    <Showcase7 />

                </Col>

                <Col className="text-center" >
                    <Showcase8 />

                </Col>
            </Row>

        </Container>

        <Container style={{ width: "900px" }} >

            <Row style={{ marginTop: "90px" }}>
                <Col className="text-center" >

                    <h3 className="text-center" style={{ fontWeight: "normal", fontFamily: "Roboto", color: "#ffffff" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.

</h3>

                </Col>
            </Row>


        </Container>

    </div >



)

export default ShowcaseSection
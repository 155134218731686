import React from "react"


import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"

import FormControl from "react-bootstrap/FormControl"
import Form from "react-bootstrap/Form"


const ContactSection = () => (

    <div id="contact" style={{ backgroundColor: "#f5f5f5", height: "100%", width: "100%", paddingTop: "30px" }}>
        <Container style={{ maxWidth: "900px" }} >

            <Row style={{ paddingBottom: "80px" }}>
                <Col><p className="h2 text-center" style={{ marginTop: "70px", fontWeight: "normal", fontFamily: "Roboto" }}>Have Questions? Get them Answered here!</p>
                    <h3 className="text-center" style={{ marginTop: "20px", fontWeight: "normal", fontFamily: "Roboto", lineHeight: "38px" }}>Our experienced custom Email Designer &amp; Developers are always happy to answer
                    any questions you may have!
</h3>
                    {/* <h4 className="text-center" style={{ fontFamily: "Roboto", fontWeight: "normal", lineHeight: "28px" }}>Whether you are looking at building your own Email marketing strategy and only require 1 email template or your a Marketing Angency looking for a reliable Email Developer, we are here to serve you.</h4> */}

                    <div style={{ maxWidth: "600px", marginTop: "80px", marginLeft: "auto", marginRight: "auto", fontFamily: "Roboto" }}>

                        <Form action="https://formspree.io/mwkrlvlp"
                            method="POST">
                            <Form.Group className="mb-4" controlId="formBasicName">

                                <Form.Control required={true} type="name" name="name" placeholder="Name" />
                                <Form.Text className="text-muted">

                                </Form.Text>
                            </Form.Group>


                            <Form.Group className="mb-4" controlId="formBasicEmail">

                                <Form.Control required={true} type="email" name="_replyto" placeholder="Email" />

                            </Form.Group>

                            {/* <Form.Group controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="Agency" />
                </Form.Group> */}

                            <Form.Group controlId="formBasicTextArea">

                                <FormControl required={true} name="message" as="textarea" aria-label="With textarea" placeholder="Enter your message here" style={{ fontFamily: "Roboto", height: "180px" }} />
                            </Form.Group>

                            <Button variant="primary" type="submit" >
                                Submit
  </Button>
                        </Form>
                    </div>








                </Col>
            </Row>

        </Container>



    </div >



)

export default ContactSection
import React from "react"
import { Link } from "gatsby"
import ResponsiveHtmlDevelopment from "./pricingSectionParts/responsiveHtmlDevelopment"
import EmailClientTestReport from "./pricingSectionParts/emailClientTestReport"
import SwappableComponents from './pricingSectionParts/swappableComponents'
import MailChimpTemplateReady from './pricingSectionParts/mailChimpTemplateReady'
import DirectCommunication from './pricingSectionParts/directCommunication'
import LayoutDesign from './pricingSectionParts/layoutDesign'

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"
import ListGroup from "react-bootstrap/ListGroup"
import ListGroupItem from "react-bootstrap/ListGroupItem"


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons'

import '../../scss/style.scss'

const PricingSection = () => (

    <div id="pricing" style={{ backgroundColor: "#ffffff", height: "100%", width: "100%", paddingTop: "30px" }}>
        {/* <div id="pricing" style={{ backgroundImage: "linear-gradient(to top, #dfe9f3 0%, white 100%)", height: "100%", width: "100%", paddingTop: "30px" }}> */}

        <Container style={{ Maxwidth: "900px" }} fluid="md" >
            <Row className="justify-content-lg-center "  >
                <Col className="text-center" >
                    <p className="h2 text-center" style={{ marginTop: "70px", fontWeight: "normal", fontFamily: "Roboto" }}>Transparent, Affordable Pricing</p>
                    <h3 className="text-center" style={{ marginTop: "20px", fontWeight: "normal", fontFamily: "Roboto" }}>All Packages include <span style={{ fontWeight: "bold", color: "#39ace7" }}>24/7</span> Direct Communication Access
</h3>
                    <Link to="/checkout/" style={{ textDecoration: "none", color: "#ffffff" }}> <Button className="shadow" variant="primary" size="lg" style={{ fontFamily: "Roboto", marginTop: "40px", paddingRight: "40px", paddingLeft: "40px" }}> Order Request </Button> </Link>
                </Col>
            </Row>


            <Row className="justify-content-lg-center" style={{ marginTop: "20px" }}>

                {/* <Col sm="6">

                    <Card style={{ maxWidth: '18rem', marginLeft: "auto", marginRight: "auto", marginTop: "60px" }} className="shadow">
                        <Card.Header className="text-center" style={{ backgroundColor: "#ebeef1" }}>
                            <p className="h4" style={{ fontFamily: "Roboto" }}>HTML Only Package</p>
                            <p style={{ fontFamily: "Roboto", marginBottom: "0px" }}>Responsive HTML
Email Development , <br />25+ Email Client Test Report, <br />MailChimp Template Ready, <br /><span style={{ color: "#39ace7", fontWeight: "bold" }}>24/7</span> Direct Communication<br />&nbsp;</p>
                        </Card.Header>
                        <ListGroup variant="flush">
                            <ListGroup.Item className="text-center">
                                <p className="priceSection" style={{ fontFamily: "Roboto", fontSize: "60px", fontWeight: "bold", marginBottom: "0px" }}>$75<span style={{ fontSize: "14px", fontWeight: "normal" }}> / USD</span></p>
                            </ListGroup.Item>
                            <ListGroupItem className="text-center" style={{ backgroundColor: "#f7f9fa" }}>
                                <h3 style={{ marginBottom: "0px" }}>Includes</h3>
                            </ListGroupItem>





                            <ListGroup.Item style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                <ResponsiveHtmlDevelopment />
                            </ListGroup.Item>


                            <ListGroup.Item style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                <EmailClientTestReport />
                            </ListGroup.Item>



                            <ListGroup.Item style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                <MailChimpTemplateReady />
                            </ListGroup.Item>



                            <ListGroup.Item style={{ paddingTop: "0px", paddingBottom: "0px", backgroundColor: "#39ace7", color: "#ffffff" }}>
                                <DirectCommunication />
                            </ListGroup.Item>

                        </ListGroup>
                    </Card>

                </Col> */}



                {/* Services Section

                <Col lg="4" md="12" sm="12">
                    <Card style={{ maxWidth: '18rem', marginLeft: "auto", marginRight: "auto", marginTop: "60px" }} className="shadow">
                        <Card.Header className="text-center" style={{ backgroundColor: "#ebeef1" }}>
                       
                <p className="h4" style={{ fontFamily: "Roboto" }}>Services</p>
          
                        </Card.Header>

            <ListGroup variant="flush">
        


                <ListGroup.Item style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                    <table style={{ margin: "0px", fontFamily: "Roboto" }}>
                        <tr>
                            <td style={{
                                border: "none", borderRight: "1px solid rgba(0, 0, 0, 0.125)", width: "80%"
                            }}>
                                <p style={{ marginBottom: "0px", fontWeight: "bold" }}>Email Design</p>
                                <p style={{ marginBottom: "0px", fontWeight: "normal", fontSize: "14px" }}>Our custom Email Designer will work with you to make your vision a reality.  <br /> Unlimited components and designed around responsive screen sizes. Both Desktop and Mobile artwork will be created with unlimited revisions.
                                    
                                </p>


                                <tr style={{ border: "none" }}>
                                    <td style={{ padding: "5px", width: "90%", border: "none" }}>
                                        <p style={{ marginBottom: "0px", fontWeight: "bold", fontSize: "14px" }}>Unlimited Components </p></td>
                                    <td style={{ padding: "5px", width: "10%", border: "none" }}>   <FontAwesomeIcon icon={faCheck} color="#39ace7" /></td>
                                </tr>

                                <tr style={{ border: "none" }}>
                                    <td style={{ padding: "5px", width: "90%", border: "none" }}>
                                        <p style={{ marginBottom: "0px", fontWeight: "bold", fontSize: "14px" }}>Unlimited Revisions</p></td>
                                    <td style={{ padding: "5px", width: "10%", border: "none" }}>    <FontAwesomeIcon icon={faCheck} color="#39ace7" /></td>
                                </tr>

                                <tr style={{ border: "none" }}>
                                    <td style={{ padding: "5px", width: "90%", border: "none" }}>
                                        <p style={{ marginBottom: "0px", fontWeight: "bold", fontSize: "14px" }}>Responsive Design</p>
                                        <p style={{ marginBottom: "0px", fontWeight: "normal", fontSize: "14px" }}>(Desktop & Mobile)</p>
                                    </td>
                                    <td style={{ padding: "5px", width: "10%", border: "none" }}>    <FontAwesomeIcon icon={faCheck} color="#39ace7" /></td>
                                </tr>

                            </td>
                            <td className="text-center" style={{ border: "none", }}>

                                $125 /USD
                                        </td>
                        </tr>
                    </table>
                </ListGroup.Item>





                <ListGroup.Item style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                    <table style={{ margin: "0px", fontFamily: "Roboto" }}>
                        <tr>
                            <td style={{
                                border: "none", borderRight: "1px solid rgba(0, 0, 0, 0.125)", width: "80%"
                            }}>
                                <p style={{ marginBottom: "0px", fontWeight: "bold" }}>Responsive HTML Development Only</p>
                                <p style={{ marginBottom: "0px", fontWeight: "normal", fontSize: "14px" }}>Our experienced Email Developer will build quality HTML code around responsive screen sizes (Desktop, Tablet, Mobile).<br /> <span style={{ color: "#39ace7", fontWeight: "bold" }}>Code complete within 24 hours</span> </p>
                            </td>
                            <td className="text-center" style={{ border: "none" }}>

                                $145 /USD
                                        </td>
                        </tr>
                    </table>
                </ListGroup.Item>





                <ListGroup.Item style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                    <table style={{ margin: "0px", fontFamily: "Roboto" }}>
                        <tr>
                            <td style={{
                                border: "none", borderRight: "1px solid rgba(0, 0, 0, 0.125)", width: "80%"
                            }}>
                                <p style={{ marginBottom: "0px", fontWeight: "bold" }}>Email Client Test Report</p>
                                <p style={{ marginBottom: "0px", fontWeight: "normal", fontSize: "14px" }}>The HTML markup wil be testing against
                                25+ of the most popular email
                                clients to enure your email
                        looks great across the board and a test report will be supplied.<br />
                                    <span style={{ color: "#39ace7", fontWeight: "bold" }}> Peace of Mind </span>  </p>

                            </td>
                            <td className="text-center" style={{ border: "none" }}>
                                $15 /USD
                                        </td>
                        </tr>
                    </table>
                </ListGroup.Item>

                <ListGroupItem className="text-center" style={{ backgroundColor: "#3AACE7", color: "#ffffff" }}>
                    <h3 style={{ marginBottom: "0px" }}>Other Services</h3>
                </ListGroupItem>


                <ListGroup.Item style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                    <table style={{ margin: "0px", fontFamily: "Roboto" }}>
                        <tr>
                            <td style={{
                                border: "none", borderRight: "1px solid rgba(0, 0, 0, 0.125)", width: "80%"
                            }}>
                                <p style={{ marginBottom: "0px", fontWeight: "bold" }}>Custom Logo Creation</p>
                                <p style={{ marginBottom: "0px", fontWeight: "normal", fontSize: "14px" }}>Our experienced Logo Creator will design a high quality vector logo<br /> <span style={{ color: "#39ace7", fontWeight: "bold" }}>Stand Out Branding</span> </p>
                            </td>
                            <td className="text-center" style={{ border: "none" }}>

                                $70 /USD
                                        </td>
                        </tr>
                    </table>
                </ListGroup.Item>





            </ListGroup>


                    </Card>
                </Col>

 */}



                <Col Col lg="4" md="12" sm="12" >

                    <Card style={{ maxWidth: '18rem', marginLeft: "auto", marginRight: "auto", marginTop: "60px" }} className="shadow">
                        <Card.Header className="text-center" style={{ backgroundColor: "#ebeef1" }}>
                            <p className="h4" style={{ fontFamily: "Roboto" }}>Complete Package</p>
                            {/* <p className="h4" style={{ fontFamily: "Roboto" }}>Personal</p> */}
                            <p style={{ fontFamily: "Roboto", marginBottom: "0px" }}>Responsive Email Design, <br />Responsive HTML
Email Development ,
{/* <br />25+ Email Client Test Report,  */}
                                {/* <br />Unlimited Components, */}
                                {/* <br />Mailchimp Template Ready  */}
                                <br /><span style={{ color: "#39ace7", fontWeight: "bold" }}>24/7</span> Direct Communication</p>
                        </Card.Header>
                        <ListGroup variant="flush">
                            <ListGroup.Item className="text-center">
                                <p style={{ fontFamily: "Roboto", fontSize: "60px", fontWeight: "bold", marginBottom: "0px" }}>
                                    {/* $215 */}
                                    $110

                                    <span style={{ fontSize: "14px", fontWeight: "normal" }}> / USD</span></p>
                                <p style={{ color: "#39ace7", fontFamily: "Roboto", fontSize: "18px", fontWeight: "bold", marginBottom: "0px" }}>SAVE $$$</p>
                                {/* <Link to="/checkout" style={{ textDecoration: "none", color: "#ffffff" }}> <Button variant="primary" size="sm" style={{ fontFamily: "Roboto", marginTop: "5px", marginBottom: "5px", paddingRight: "20px", paddingLeft: "20px" }}> Order Now </Button> </Link> */}
                            </ListGroup.Item>

                            <ListGroupItem className="text-center" style={{ backgroundColor: "#f7f9fa" }}>
                                <h3 style={{ marginBottom: "0px" }}>Includes</h3>
                            </ListGroupItem>


                            <ListGroup.Item style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                <LayoutDesign />
                            </ListGroup.Item>





                            <ListGroup.Item style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                <ResponsiveHtmlDevelopment />
                            </ListGroup.Item>


                            {/* <ListGroup.Item style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                <EmailClientTestReport />
                            </ListGroup.Item> */}


                            {/* <ListGroup.Item style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                <SwappableComponents />
                            </ListGroup.Item> */}


                            {/* 
                            <ListGroup.Item style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                <MailChimpTemplateReady />
                            </ListGroup.Item> */}



                            <ListGroup.Item style={{ paddingTop: "0px", paddingBottom: "0px", backgroundColor: "#39ace7", color: "#ffffff" }}>
                                <DirectCommunication />
                            </ListGroup.Item>

                            <ListGroupItem className="text-center" style={{ backgroundColor: "#ebeef1" }}>
                                <h3 style={{ marginBottom: "0px" }}>Extras</h3>
                            </ListGroupItem>


                            <ListGroup.Item style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                <table style={{ margin: "0px", fontFamily: "Roboto" }}>
                                    <tr>
                                        <td style={{
                                            border: "none", borderRight: "1px solid rgba(0, 0, 0, 0.125)", width: "80%"
                                        }}>

                                            <p style={{ marginBottom: "0px", fontWeight: "bold" }}>Unlimited Template Components</p>
                                            <p style={{ marginBottom: "0px", fontWeight: "normal", fontSize: "14px" }}>Your Custom Email Template will contain unlimited 'swappable' components so that one template can be used for many different purposes.<br />Only available within the body of the email (Header and Footer will need to stay static);
                                                <span style={{ color: "#39ace7", fontWeight: "bold" }}> Excellent Value with Unlimited Variations </span>
                                            </p>

                                        </td>
                                        <td className="text-center" style={{ border: "none" }}>

                                            {/* $145 /USD */}
                                            $48 /USD
                                        </td>
                                    </tr>
                                </table>
                            </ListGroup.Item>





                            <ListGroup.Item style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                <table style={{ margin: "0px", fontFamily: "Roboto" }}>
                                    <tr>
                                        <td style={{
                                            border: "none", borderRight: "1px solid rgba(0, 0, 0, 0.125)", width: "80%"
                                        }}>
                                            <p style={{ marginBottom: "0px", fontWeight: "bold" }}>Email Client Test Report</p>
                                            <p style={{ marginBottom: "0px", fontWeight: "normal", fontSize: "14px" }}>The HTML markup wil be testing against
                                            25+ of the most popular email
                                            clients to enure your email
                        looks great across the board and a test report will be supplied.<br />
                                                <span style={{ color: "red" }}>*price is per email variation</span>
                                                <br />
                                                <span style={{ color: "#39ace7", fontWeight: "bold" }}> Peace of Mind </span>  </p>

                                        </td>
                                        <td className="text-center" style={{ border: "none" }}>
                                            $8 /USD
                                        </td>
                                    </tr>
                                </table>
                            </ListGroup.Item>

                        </ListGroup>


                    </Card>

                </Col>







                <Col Col lg="4" md="12" sm="12" >
                    <Card style={{ maxWidth: '18rem', marginLeft: "auto", marginRight: "auto", marginTop: "60px" }} className="shadow">
                        <Card.Header className="text-center" style={{ backgroundColor: "#ebeef1" }}>
                            {/* <p className="h4" style={{ fontFamily: "Roboto" }}>Complete Package</p> */}
                            <p className="h4" style={{ fontFamily: "Roboto" }}>
                                {/* Services */}
                                Other Services
                                </p>
                            {/* <p style={{ fontFamily: "Roboto", marginBottom: "0px" }}>Responsive Email Design, <br />Responsive HTML
Email Development , <br />25+ Email Client Test Report, <br />MailChimp Template Ready, <br /><span style={{ color: "#39ace7", fontWeight: "bold" }}>24/7</span> Direct Communication</p> */}
                        </Card.Header>

                        <ListGroup variant="flush">
                            {/* <ListGroup.Item className="text-center">
                                <Link to="#contact" style={{ textDecoration: "none", color: "#ffffff" }}> <Button className="shadow" variant="primary" size="lg" style={{ fontFamily: "Roboto", marginTop: "18px", marginBottom: '18px', paddingRight: "40px", paddingLeft: "40px" }}> Contact Us </Button> </Link>
                            </ListGroup.Item>
                            <ListGroupItem className="text-center" style={{ backgroundColor: "#f7f9fa" }}>
                                <h3 style={{ marginBottom: "0px" }}>Includes</h3>
                            </ListGroupItem> */}


                            <ListGroup.Item style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                <table style={{ margin: "0px", fontFamily: "Roboto" }}>
                                    <tr>
                                        <td style={{
                                            border: "none", borderRight: "1px solid rgba(0, 0, 0, 0.125)", width: "80%"
                                        }}>
                                            <p style={{ marginBottom: "0px", fontWeight: "bold" }}>Email Design Only</p>
                                            <p style={{ marginBottom: "0px", fontWeight: "normal", fontSize: "14px" }}>Our custom Email Designer will work with you to make your vision a reality.  <br /> Designed around responsive screen sizes (Desktop, Tablet, Mobile). <br /> <span style={{ color: "#39ace7", fontWeight: "bold" }}>Unlimited Revisions</span> </p>
                                        </td>
                                        <td className="text-center" style={{ border: "none" }}>

                                            {/* $125 /USD */}
                                            $75 /USD
                                        </td>
                                    </tr>
                                </table>
                            </ListGroup.Item>





                            <ListGroup.Item style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                <table style={{ margin: "0px", fontFamily: "Roboto" }}>
                                    <tr>
                                        <td style={{
                                            border: "none", borderRight: "1px solid rgba(0, 0, 0, 0.125)", width: "80%"
                                        }}>
                                            <p style={{ marginBottom: "0px", fontWeight: "bold" }}>Responsive HTML Development Only</p>
                                            <p style={{ marginBottom: "0px", fontWeight: "normal", fontSize: "14px" }}>Our experienced Email Developer will build quality HTML code around responsive screen sizes (Desktop, Mobile).<br />
                                                <span style={{ color: "#39ace7", fontWeight: "bold" }}>Quality Code, Maximum Deliverability</span>
                                            </p>
                                        </td>
                                        <td className="text-center" style={{ border: "none" }}>

                                            {/* $145 /USD */}
                                            $64 /USD
                                        </td>
                                    </tr>
                                </table>
                            </ListGroup.Item>






                            {/* 
                            <ListGroupItem className="text-center" style={{ backgroundColor: "#3AACE7", color: "#ffffff" }}>
                                <h3 style={{ marginBottom: "0px" }}>Other Services</h3>
                            </ListGroupItem> */}


                            {/* <ListGroup.Item style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                <table style={{ margin: "0px", fontFamily: "Roboto" }}>
                                    <tr>
                                        <td style={{
                                            border: "none", borderRight: "1px solid rgba(0, 0, 0, 0.125)", width: "80%"
                                        }}>
                                            <p style={{ marginBottom: "0px", fontWeight: "bold" }}>Custom Logo Creation</p>
                                            <p style={{ marginBottom: "0px", fontWeight: "normal", fontSize: "14px" }}>Our experienced Logo Creator will design a high quality vector logo<br /> <span style={{ color: "#39ace7", fontWeight: "bold" }}>Stand Out Branding</span> </p>
                                        </td>
                                        <td className="text-center" style={{ border: "none" }}>

                                            $70 /USD
                                        </td>
                                    </tr>
                                </table>
                            </ListGroup.Item> */}

                            <ListGroup.Item style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                <table style={{ margin: "0px", fontFamily: "Roboto" }}>
                                    <tr>
                                        <td style={{
                                            border: "none", borderRight: "1px solid rgba(0, 0, 0, 0.125)", width: "80%"
                                        }}>
                                            <p style={{ marginBottom: "0px", fontWeight: "bold" }}>Content Writing</p>
                                            <p style={{ marginBottom: "0px", fontWeight: "normal", fontSize: "14px" }}>Our in-house creative writer will form punchy content for your email template<br /> <span style={{ color: "#39ace7", fontWeight: "bold" }}>Get the Message Accross </span> </p>
                                        </td>
                                        <td className="text-center" style={{ border: "none", paddingLeft: '0.72rem' }}>

                                            $0.10 <span style={{ fontSize: '10px' }}>per word</span> <br></br> /USD
                                        </td>
                                    </tr>
                                </table>
                            </ListGroup.Item>





                        </ListGroup>


                    </Card>
                </Col >





                {/* 
                <Col lg="4" md="6" sm="6">
                    <Card style={{ maxWidth: '18rem', marginLeft: "auto", marginRight: "auto", marginTop: "60px" }} className="shadow">
                        <Card.Header className="text-center" style={{ backgroundColor: "#ebeef1" }}>
                            <p className="h4" style={{ fontFamily: "Roboto" }}>Agency</p>
                        </Card.Header>

                        <ListGroup variant="flush">
                            <ListGroup.Item className="text-center">
                                <Link to="#contact" style={{ textDecoration: "none", color: "#ffffff" }}> <Button variant="secondary" size="lg" style={{ fontFamily: "Roboto", marginTop: "18px", marginBottom: '18px', paddingRight: "40px", paddingLeft: "40px" }}> Contact Us </Button> </Link>
                            </ListGroup.Item>


                        </ListGroup>


                    </Card>
                </Col>


 */}


            </Row >

            <Row style={{ marginTop: "90px" }}>
                <Col className="text-center" >

                    <h3 className="text-center" style={{ fontWeight: "normal", fontFamily: "Roboto", marginBottom: "80px", lineHeight: "38px" }}>Simply submit your order request and our Email Developer will make contact with you ASAP to discuss your requirements and get the ball rolling.

</h3>

                </Col>
            </Row>


        </Container >

    </div >


)

export default PricingSection
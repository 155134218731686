import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/images/image"
import SEO from "../components/seo"
import HeroImg from "../components/images/heroImg"
import Showcase1 from "../components/images/showcase1"
import Showcase2 from "../components/images/showcase2"
import Showcase3 from "../components/images/showcase3"
import Showcase4 from "../components/images/showcase4"
import Showcase5 from "../components/images/showcase5"
import Showcase6 from "../components/images/showcase6"
import Showcase7 from "../components/images/showcase7"
import Showcase8 from "../components/images/showcase8"
import Blog1 from "../components/images/blog1"
import Blog2 from "../components/images/blog2"
import Blog3 from "../components/images/blog3"
import Blog4 from "../components/images/blog4"

import HeroSection from "../components/home-page/heroSection"
import PricingSection from "../components/home-page/pricingSection"
import ShowcaseSection from "../components/home-page/showcaseSection"
import ContactSection from "../components/home-page/contactSection"
import ArchiveSection from "../components/home-page/archiveSection"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"
import ListGroup from "react-bootstrap/ListGroup"
import ListGroupItem from "react-bootstrap/ListGroupItem"
import InputGroup from "react-bootstrap/InputGroup"
import FormControl from "react-bootstrap/FormControl"
import Form from "react-bootstrap/Form"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faMapMarkerAlt, faEnvelope, faFileAlt } from '@fortawesome/free-solid-svg-icons'


const Footer = () => (
    <div style={{ backgroundColor: "#192128", minHeight: "120px", width: "100%", color: "#ffffff", bottom: '0px' }}>
        <footer style={{ fontSize: "18px" }}>
            <Container style={{ fontFamily: "Roboto", }} >

                <Row style={{ paddingTop: "30px" }}>
                    <Col className="text-left" style={{ paddingTop: "50px", paddingBottom: "30px" }}>
                        <FontAwesomeIcon icon={faMapMarkerAlt} color="#39ace7" /> &nbsp; Brisbane, Queensland, Australia
                    <br />
                        <br />
                        <FontAwesomeIcon icon={faEnvelope} color="#39ace7" /> &nbsp; <a style={{ color: "#ffffff", textDecoration: "none" }} href="mailto:enquiries@mailsculpt.com">enquiries@mailsculpt.com</a>
                        <br />
                        <br />
                        <FontAwesomeIcon icon={faFileAlt} color="#39ace7" /> <Link to="/terms/" style={{ textDecoration: "none", color: "#ffffff" }}>&nbsp; Terms of use</Link>






                    </Col>

                    <Col lg="4" className="text-left" style={{ borderLeft: "solid 1px #39ace7" }}>

                        <h3 style={{ textDecoration: "underline", textDecorationColor: "#39ace7", marginLeft: "20px" }}>About Us</h3>
                        <p style={{ color: "#ededed", marginLeft: "20px" }}>Here at Mail Sculpt, our passion is Email! </p>
                        <p style={{ color: "#ededed", marginLeft: "20px" }}>


                            The overall service we offer is unique as we can offer tailored and customised solutions to help our clients get their individual message across, whilst still ensuring the highest quality work at an affordable price.</p>

                    </Col>
                </Row>


                <Row style={{ marginTop: "40px", paddingBottom: "40px" }}>
                    <Col className="text-center">
                        <p style={{ fontWeight: "normal", fontSize: "22px" }}>  © {new Date().getFullYear()}, Mail Sculpt
          {` `} </p>
                    </Col>
                </Row>

            </Container>
        </footer>



    </div >

)

export default Footer
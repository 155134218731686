import React from "react"

import HeroImg from "../images/heroImg"
import heroGif from "../../images/SmallScreensGif.gif"
import HeroVid from "../../images/HeroVideo.mp4"
import HeroVid2 from "../../images/HeroVideo.webm"
import HeroVidImage from "../../images/HeroVideoImage.jpg"
import heroComputers from "../../images/HeroScreensGIF_10thJune.gif"
import heroBuilders from "../../images/MailSculpt_Hero_01.png"
import ZohoHero from "../../images/zohodevelopment.png"
// import heroComputers from "../../images/VectorStyleHero.png"
import mailSculptHero from "../../images/MailSculptHero-Joan.png"


import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import '../../scss/style.scss'





const HeroSection = () => (

    // <div style={{ backgroundColor: "#39ace7", height: "100%", width: "100%", paddingBottom: "30px" }}>
    <div className="heroBackground2" style={{ height: "100%", width: "100%", paddingBottom: "30px" }}>
        {/* <div style={{ backgroundColor: "#007dbd", height: "100%", width: "100%", }}> */}
        {/* <div style={{ backgroundColor: "#40AFE4", height: "100%", width: "100%" }}> */}
        <Container>
            <Row className="justify-content-lg-center " >
                <Col style={{ maxWidth: "900px" }}>
                    <h1 className="heroh1 text-center" style={{ marginTop: "110px", color: "#000000", fontSize: "46px", fontWeight: "normal", fontFamily: "Roboto" }}>Custom Email Development Done Right</h1>

                    {/* <h1 className="heroh1 text-center" style={{ marginTop: "110px", color: "#ffffff", fontWeight: "normal", fontFamily: "Roboto" }}>When you need your Email exactly Sculpted to suit your Campaign / Business </h1> */}
                    {/* <h3 className="text-center" style={{ color: "#ffffff", fontFamily: "Roboto", fontWeight: "normal", }}>No more pre-made email templates that
don’t suit your ideas / business</h3> */}
                </Col>
            </Row>
            <Row className="justify-content-lg-center">

                <Col style={{ maxWidth: "900px" }}>
                    <div style={{ maxWidth: "900px", marginLeft: "auto", marginRight: "auto", marginTop: "0px" }}>

                        {/* <HeroImg /> */}
                        {/* <img src={heroComputers} /> */}
                        {/* <img src={heroComputers} /> */}
                        {/* <img src={heroBuilders} /> */}
                        <img src={mailSculptHero} />
                        {/* <img src={ZohoHero} /> */}
                        {/* <iframe
                            src={HeroVid}
                            title="MailSculpt"
                            allow="autoplay; "
                            width="500px"
                            height="500px"
                            frameBorder="0"
                            webkitallowfullscreen="true"
                            mozallowfullscreen="true"
                            allowFullScreen /> */}
                        {/* <video
                            poster="HeroVideoImage.jpg"
                            loop
                            muted
                            autoPlay
                            preload="auto"
                            width="100%"
                            playsInline
                        >
                            <source src={HeroVid2} type="video/webm" />
                            <source src={HeroVid} type="video/mp4" />

                        </video> */}

                    </div>
                </Col>
            </Row>
            <Row className="justify-content-lg-center" >
                <Col lg="9">
                    {/* <h3 className="text-center" style={{ color: "#ffffff", marginTop: "60px", fontFamily: "Roboto", fontWeight: "normal", lineHeight: "38px" }}>Our custom Email Designer and Email Developer strategist will work with you to ensure
                    that not only your Email Campaign and overal EDM Design looks fantastic, but you message
truly hits home. </h3> */}
                    <h3 className="text-center" style={{ color: "#192227", marginTop: "10px", fontFamily: "Roboto", fontWeight: "normal", lineHeight: "38px" }}>

                        We are Email Design &amp; Development Specialists. Our goal is to ensure that your Email Campaign and overall EDM Design look fantastic and that your message truly hits home with your audience.
                    </h3>
                    {/* <h2 className="text-center" style={{ color: "#ffffff", marginBottom: "80px", fontFamily: "Roboto", lineHeight: "38px" }}>
                        At Mail Sculpt, we take Email Development very serious!
</h2> */}
                </Col>
            </Row>

        </Container>

    </div >


)

export default HeroSection